.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  overflow: hidden;
}

.introDiv {
  height: 64px;
  background-color: var(--primary-color);
}

.inbetween {
  min-height: 600px;
  background-position: center 4em;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 94%;
}

.bg-one {
  background-image: url("../images/bgImages/dungeonBasic.jpg");
}

.bg-two {
  background-image: url("../images/bgImages/neoKamiMtg.jpg");
}

.bg-three {
  background-image: url("../images/bgImages/warhammer.jpg");
}

.bg-four {
  background-image: url("../images/bgImages/catan.jpg");
}

.bg-five {
  background-image: url("../images/bgImages/event.jpg");
}

a {
  text-decoration: none;
}

.font-style {
  font-size: xx-large;
  text-align: center;
  font-family: "Fondamento";
  color: rgb(255, 245, 230);
}

.line-break {
  border-width: 0;
  background-color: rgb(8, 8, 8);
  max-width: 25%;
  height: 3px;
  color: grey;
  /* margin-bottom: 2em; */
}

footer {
  background-color: var(--secondary-color);
  height: 200px;
}

@media only screen and (max-width: 1920px) {
}
@media only screen and (max-width: 1366px) {
  .inbetween {
    background-position: center 3.5em;
  }
}
@media only screen and (max-width: 1024px) {
  .inbetween {
    min-height: 500px;
  }
}
@media only screen and (max-width: 960px) {
  .inbetween {
    background-position: center 3em;
  }
}
@media only screen and (max-width: 864px) {
  .spacer1 {
    height: 600px;
  }

  .spacer2 {
    height: 200px;
  }
}
@media only screen and (max-width: 667px) {
  .spacer1 {
    height: 600px;
  }

  .spacer2 {
    height: 200px;
  }
}
@media only screen and (max-width: 420px) {
  .inbetween {
    min-height: 300px;

    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 40em;
  }
}
