#card-start {
  position: relative;
  top: -12em;
}

.right-image {
  background: linear-gradient(
    var(--ang, 105deg),
    var(--secondary-color) calc(50% - 1.5px),
    rgba(255, 255, 255, 0) calc(60% + 0.5px)
  );
}

.left-image {
  background: linear-gradient(
    var(--ang, 75deg),
    rgba(255, 255, 255, 0) calc(40% - 1.5px),
    var(--secondary-color) calc(50% + 0.5px)
  );
}

.about {
  /* background: linear-gradient(
    var(--ang, 0deg),
    rgba(10, 0, 0, 1) calc(50% - 1.5px),
    rgba(97, 4, 4, 0.9) calc(80% + 0.5px)
  ); */
  border: var(--secondary-color) solid 1px;
  height: 900px;
  display: flex;
  flex-direction: row;

  background: -webkit-linear-gradient(
    left,
    var(--primary-color) 15%,
    var(--secondary-color) 44%,
    var(--secondary-color) 58%,
    var(--primary-color) 85%
  );
}

.feature-card {
  border: var(--secondary-color) solid 1px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card-info {
  font-size: xx-large;
  text-align: center;
  font-family: "Fondamento";
  color: rgb(255, 245, 230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  padding: 2% 2% 2% 2%;
}

.card-title {
  font-size: 2em;
  border-bottom: var(--primary-color) solid 2px;
}

.img-box {
  width: 60%;
  height: 500px;
}

.image {
  position: relative;
  z-index: -5;
  max-height: 500px;
  width: 100%;
  height: 100%;
}

.img-about {
  height: 100%;
}

.left-dual-img {
  margin-top: 80px;
}

.right-dual-img {
  margin-top: 80px;
}

@media only screen and (max-width: 1366px) {
  .feature-card {
    height: 400px;
  }

  .img-box {
    height: 400px;
  }

  .card-title {
    font-size: 1.6em;
  }
}
@media only screen and (max-width: 1024px) {
  /* .feature-card {
    height: 330px;
  } */

  .card-title {
    font-size: 1.4em;
  }

  .card-info {
    font-size: 1.8em;
  }
}
@media only screen and (max-width: 960px) {
  /* .feature-card {
    height: 300px;
  } */

  .card-title {
    font-size: 1.4em;
  }

  .card-info {
    font-size: 1.5em;
  }
}
@media only screen and (max-width: 896px) {
  .spacer1 {
    height: 600px;
  }

  .spacer2 {
    height: 200px;
  }
}
@media only screen and (max-width: 864px) {
  .spacer1 {
    height: 600px;
  }

  .spacer2 {
    height: 200px;
  }
}
@media only screen and (max-width: 667px) {
  .spacer1 {
    height: 600px;
  }

  .spacer2 {
    height: 200px;
  }
}
@media only screen and (max-width: 420px) {
  .about {
    flex-direction: column;
    height: fit-content;
  }
  .img-about {
    display: none;
  }
  .img-box {
    display: none;
  }
  .feature-card {
    height: 250px;
  }
  .card-info {
    margin: auto;
    width: 325px;
    font-size: 1.2em;
  }
}
