.content {
  background-color: --primary-color;
  border: solid 10px #aaaaaa;
  min-height: 1000px;
  overflow: hidden;
}
.nav-gap {
  height: 66px;
}
.red-border {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  min-height: 1000px;
  overflow: hidden;
  border-width: 6px;
  border-style: solid;
  border-image: radial-gradient(#000000, #500101) 1;
}
.content-title {
  margin-top: 5%;
  padding: 5px;
  font-family: "Fondamento";
  letter-spacing: 2px;
  font-size: 3em;
  font-weight: bold;
  background-image: linear-gradient(
    to right,
    #503836 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #503836 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.3px rgb(5, 4, 3);
  border-bottom: solid 2px #aaaaaa;
}
.content-description {
  text-align: center;
  color: #aaaaaa;
  margin-top: 5%;
  padding: 5px;
  font-family: "Times New Roman", serif;
  font-size: 1.2em;
  font-weight: bold;
  max-width: 75%;
}

.product-card-box {
  max-width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: flex-start;
  justify-content: space-around;
}
