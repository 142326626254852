.footer {
  width: 100%;
  background-color: var(--secondary-color);

  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  font-family: cursive;

  font-size: 0.8em;
}

.foot-div {
  width: 33%;
  margin: 2%;
}

.copy-write {
  width: 100%;
  margin-top: 90px;
  font-family: cursive;

  font-size: 0.8em;
}

.open-times {
  padding: 5px;
  display: flex;
}

.days {
  margin-right: 5px;
}

.hours {
  margin-left: 5px;
}

@media only screen and (max-width: 1920px) {
  footer {
    height: 340px;
  }
}
@media only screen and (max-width: 1536px) {
  footer {
    height: 280px;
  }
}
@media only screen and (max-width: 1366px) {
  footer {
    height: 285px;
  }
}
@media only screen and (max-width: 1024px) {
  footer {
    height: 235px;
  }
}
@media only screen and (max-width: 960px) {
  footer {
    height: 175px;
  }
}
@media only screen and (max-width: 864px) {
}
@media only screen and (max-width: 667px) {
}
@media only screen and (max-width: 420px) {
  .footer {
    height: 240px;
  }
}
