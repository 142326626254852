.hamburger {
  display: none;
  align-items: center;
  width: 35px;

  padding: 1px;
  background-color: #500101;
  border: 1px solid rgb(107, 103, 103);
  border-radius: 5px;
}

.line {
  height: 4px;
  margin: 4px;

  border-radius: 2px;
  background-color: rgb(170, 170, 170);
}

@media only screen and (max-width: 420px) {
  .hamburger {
    display: block;
  }
}
